<template>
  <el-container>
    <Header></Header>
    <el-container>
      <Menu></Menu>
      <el-main class="main" :style="isCollapse">
        <Breadcrumb></Breadcrumb>
        <div class="content">
          <div>{{ zn ? "首页" : "Home" }}</div>
          <el-table style="width: 100%" :data="tableData" border row-key="id">
            <el-table-column
              v-for="(item, index) in col"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
            </el-table-column>
          </el-table>
          <div class="upload-content">
            <!-- 结果预览区 -->
            <!-- <section class="section" v-if="isImg">
              <div class="upload-delete">
                <i class="el-icon-delete delete" @click="handDelete"></i>
              </div>
              <div class="preview" ref="preview">
                <img class="upload-img" :src="result" alt="组合式 API" />
              </div>
            </section> -->
            <!-- 结果预览区 -->

            <!-- 选择图片 -->
            <!-- <el-button class="upload-input-group" v-else icon="el-icon-plus">
              <input
                ref="uploadInput"
                class="upload-input"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/gif"
                @change="selectFile"
              />
            </el-button> -->
            <!-- 选择图片 -->

            <!-- 用于裁切的弹窗 -->
            <el-dialog title="图片剪裁" v-model="isShowDialog">
              <!-- 图片裁切插件 -->
              <vue-picture-cropper
                :boxStyle="{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#f8f8f8',
                  margin: 'auto',
                }"
                :img="pic"
                :options="{
                  viewMode: 1,
                  dragMode: 'crop',
                  aspectRatio: 1 / 1,
                  preview: '.preview',
                }"
              />
              <!-- 图片裁切插件 -->
              <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button @click="clear">清 除</el-button>
                  <el-button @click="reset">重 置</el-button>
                  <el-button type="primary" @click="getResult">裁 切</el-button>
                </span>
              </template>
            </el-dialog>
            <!-- 用于裁切的弹窗 -->
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/header/header.vue";
import Menu from "@/components/menu/menu.vue";
import Breadcrumb from "@/components/breadcrumb/breadcrumb.vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper";

export default {
  name: "Home",
  components: {
    Header,
    Menu,
    Breadcrumb,
    VuePictureCropper,
  },
  data() {
    return {
      zn: "",
      uploadInput: "",
      pic: "",
      result: "",
      isShowDialog: false,
      isImg: false,
      type: "",
      isCollapse: {
        marginLeft: "64px",
      },
      col: [
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "姓名",
          prop: "name",
        },
        {
          label: "地址",
          prop: "address",
        },
      ],
      tableData: [
        {
          id: "1",
          date: "2016-05-01",
          name: "王小虎1",
          address: "上海市普陀区金沙江路 100 弄",
        },
        {
          id: "2",
          date: "2016-05-02",
          name: "王小虎2",
          address: "上海市普陀区金沙江路 200 弄",
        },
        {
          id: "3",
          date: "2016-05-03",
          name: "王小虎3",
          address: "上海市普陀区金沙江路 300 弄",
        },
        {
          id: "4",
          date: "2016-05-04",
          name: "王小虎4",
          address: "上海市普陀区金沙江路 400 弄",
        },
      ],
    };
  },
  methods: {
    /**
     * 选择图片
     */
    selectFile(e) {
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      // 转换为base64传给裁切组件
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // 更新裁切弹窗的图片源
        this.pic = String(reader.result);
        // 显示裁切弹窗
        this.isShowDialog = true;
      };
    },
    /**
     * 获取裁切结果
     */
    getResult() {
      // 获取生成的base64图片地址
      const base64 = cropper.getDataURL();
      // 把base64赋给结果展示区
      this.result = base64;
      // 隐藏裁切弹窗
      this.isShowDialog = false;
      this.isImg = true;
    },
    /**
     * 清除裁切框
     */
    clear() {
      cropper.clear();
    },
    /**
     * 重置默认的裁切区域
     */
    reset() {
      cropper.reset();
    },
    handDelete() {
      this.result = null;
      this.isImg = false;
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      _this.sort.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
        },
      });
    },
  },
  created() {
    this.bus.$on("isCollapse", (val) => {
      let obj = {
        marginLeft: val ? "64px" : "200px",
      };
      this.isCollapse = obj;
    });
    this.bus.$on("changeLang", (val) => {
      this.zn = val;
    });
  },
  mounted() {
    this.zn = this.$store.state.zn;
    // this.type = this.$store.state.type;
    this.rowDrop();
  },
};
</script>

<style lang="scss">
.upload-content {
  display: flex;
  align-items: flex-end;

  .upload-input-group {
    position: relative;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 1px solid #c9c9c9;
    color: #c9c9c9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;

    .upload-input {
      position: absolute;
      width: 100px;
      height: 100px;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
    }
  }

  .section {
    position: relative;
    width: 100px;
    margin-right: 20px;

    .upload-delete {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(97, 69, 69, 0.5);
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      opacity: 0;

      &:hover {
        opacity: 1;
      }

      .delete {
        cursor: pointer;
      }
    }

    .upload-img {
      display: block;
      width: 100%;
    }
  }
}
</style>
